const sackoHistory = {
  2016: {
    1: "Angi",
    2: "Gavin",
    3: "Nick V",
    sacko: "Jeff",
  },
  2017: {
    1: "Teague",
    2: "Nick V",
    3: "Gavin",
    sacko: "Nick P",
  },
  2018: {
    1: "Chris W",
    2: "Gavin",
    3: "Teague",
    sacko: "Jeff",
  },
  2019: {
    1: "Jeff",
    2: "Teague",
    3: "Angi",
    sacko: "Sara",
  },
  2020: {
    1: "Sara",
    2: "Patrick",
    3: "Som",
    sacko: "Chris M",
  },
  2021: {
    1: "Chris M",
    2: "Nick P",
    3: "Teague",
    sacko: "Angi",
  },
};

export default sackoHistory;
