import React from "react";
import "./App.css";
import History from "./History";
import Background from "./images/dave-adamson--nATH0CrkMU-unsplash.jpg";

function App() {
  let appStyle = {
    width: "100%",
  };

  let historySectionStyle = {
    width: "100%",
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundRepeat: "repeat",
    backgroundPosition: "center",
  };

  let footerStyle = {
    width: "100%",
    backgroundColor: "#32cd32",
    fontWeight: "normal",
    textAlign: "left",
    paddingLeft: "1rem",
    fontSize: "0.75rem",
  };

  return (
    <div id="app-container" style={appStyle}>
      <h1>SACKO LEAGUE</h1>
      <section className="league-history-section" style={historySectionStyle}>
        <div className="history-items-container">
          <History year={2021} />
          <History year={2020} />
          <History year={2019} />
          <History year={2018} />
          <History year={2017} />
          <History year={2016} />
        </div>
      </section>
      <footer className="site-footer" style={footerStyle}>
        <p>Crafted with ❤ by 🏈🤡 -- Last update January 2021</p>
      </footer>
    </div>
  );
}

export default App;
