import React from 'react';
import sh from "./data/sacko-history"

//construct and return a history component
function History(props) {
  const year = props.year;
  const winner = sh[year][1]
  const second = sh[year][2]
  const third = sh[year][3]

  return (
    <div className="year-block">
      <div className="year-banner">
        <h2>{year}</h2>
      </div>
      <h4>Champion: {winner}</h4>
      <h4>2nd Place: {second}</h4>
      <h4>3rd Place: {third}</h4>
      <h4>SACKO: {sh[year].sacko}</h4>
    </div>
  );
}

export default History;
